<template>
  <view-container scroll-fab>
    <v-card tile flat :style="`overflow-y: auto; ${getViewHeightStyle(56)}`">
      <markers-map :markers="markers" :geofence="getAosCoordinates" />

      <div class="pb-8 px-5">
        <section-header title="Delivery Location" class="mb-2" />
        <v-row no-gutters>
          <v-col cols="12" md="8">
            <section-header title="Your Cart" />

            <order-items
              v-model="getCartItems"
              :currency="getCartCurrency"
              class="mr-3"
          />

          </v-col>
          <v-col cols="12" md="4">
            <section-header
              rtl
              :title="$t('cart.orderSummary')"
            />
            <v-card class="mt-2">
              <store-card :store="getCartStore" banner round class="mb-1" />
              <v-card flat>
                <v-card-text>
                  Deliver To: <b>{{ getDefaultAddressStringNoTitle }}</b>
                  <v-alert
                    v-if="outOfAos"
                    type="error"
                    class="body-2 lighten-1 px-1 py-0 mb-1"
                    >Address is out of store area of service</v-alert
                  >
                </v-card-text>
                <v-simple-table dense class="grey lighten-4" light>
                  <template v-slot:default>
                    <tbody>
                      <tr v-for="(item, i) in getCartSummary" :key="i">
                        <td>{{ item.name }}</td>
                        <td class="text-right">
                          {{ getCartCurrency + " " + item.price }}
                        </td>
                      </tr>
                      <tr>
                        <td
                          :class="
                            isFreeDelivery ? 'text-decoration-line-through' : ''
                          "
                        >
                          {{ $t("cart.deliveryFees") }}
                        </td>
                        <td
                          :class="
                            isFreeDelivery
                              ? 'text-decoration-line-through text-right'
                              : 'text-right'
                          "
                        >
                          {{
                            getCartCurrency +
                              " " +
                              (getCartStore ? getCartStore.deliveryFees : 0)
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td class="font-weight-bold">
                          {{ $t("cart.grandTotal") }}
                        </td>
                        <td class="font-weight-bold text-right">
                          {{
                            getCartCurrency +
                              " " +
                              (notEmptyArray(getCartItems)
                                ? getCartGrandTotal
                                : 0)
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <v-card-text>
                  <v-alert
                    v-if="!isMinimum"
                    type="error"
                    class="body-2 lighten-1 px-1 py-0 mb-1"
                    dense
                  >
                    The minimum order price for this store is ({{
                      getMinOrderPrice
                    }}), please add a few more items.
                  </v-alert>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    depressed
                    color="secondary primary--text"
                    @click="goToCart">
                    <v-icon>
                      mdi-cart
                    </v-icon>
                    Back To Cart
                  </v-btn>
                  <v-spacer />
                  <v-btn
                    depressed
                    color="primary secondary--text"
                    :disabled="!notEmptyArray(getCartItems) || isCartLocked || outOfAos || !isMinimum"
                    @click="tryCheckoutCart">
                    <v-icon>
                      mdi-check-bold
                    </v-icon>
                    Checkout
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-card>
          </v-col>
        </v-row>
        <br />
      </div>
    </v-card>
    <locked-cart store-items address />
  </view-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ViewContainer from "@/common/layouts/ViewContainer";
import SectionHeader from "@/common/components/SectionHeader";
import MarkersMap from "@/common/components/MarkersMap";
import OrderItems from "@/views/orders/OrderItems";
import LockedCart from "./LockedCart";
import StoreCard from "@/views/stores/StoreCard";

export default {
  name: "Cart",
  components: {
    ViewContainer,
    MarkersMap,
    SectionHeader,
    OrderItems,
    StoreCard,
    LockedCart,
  },
  data() {
    return {
      markers: [],
      outOfAos: false,
    };
  },
  mounted() {
    this.loadStore(this.getStoreIdParam);
    const { activeAddress } = this.$getUserInfo();
    this.isLocationInAos({
      storeId: this.getStoreIdParam,
      latitude: activeAddress?.latitude,
      longitude: activeAddress?.longitude,
    }).then((inAos) => (this.outOfAos = !inAos));
    this.markers = this.getMarkers;
  },
  computed: {
    ...mapGetters("cart", [
      "getCartStore",
      "getCartItems",
      "getCartTotalPrice",
      "getCartGrandTotal",
      "isFreeDelivery",
      "getCartCurrency",
      "isCartLocked",
    ]),
    ...mapGetters("stores", ["getStoreById"]),
    ...mapGetters("addresses", ["getAddresses"]),

    getStoreIdParam() {
      return parseInt(this.$route.params.storeId);
    },
    getStore() {
      return this.getStoreById(this.getStoreIdParam);
    },
    getMarkers() {
      const { activeAddress: a } = this.$getUserInfo();
      const store = this.getStore;

      return [
        {
          icon: "mdi-headset",
          color: "#195567",
          class: "secondary pin2",
          status: "MAKZANI",
          latitude: store.latitude,
          longitude: store.longitude,
          click: () => {},
        },
        {
          icon: "mdi-home-map-marker",
          color: "white",
          class: "blue darken-2 pin",
          // latitude: 12.75262,
          // longitude: 44.90045,
          latitude: a.latitude || 12.75262,
          longitude: a.longitude || 44.90045,
          target: true,
          click: () => {},
        },
        // {
        //   // icon: "mdi-moped",
        //   icon: "mdi-truck-fast-outline",
        //   color: "black",
        //   class: "yellow circle",
        //   status: "Out for delivery",
        //   latitude: 12.7543,
        //   longitude: 44.8955,
        //   click: () => {},
        // },
      ];
    },
    getAosCoordinates() {
      const store = this.getCartStore;
      if (!store || !store.aosGeom) {
        return [];
      }
      return store.aosGeom.geometry.coordinates;
    },
    getCartSummary() {
      const summary = [
        { name: this.$t("cart.itemsPrice"), price: this.getCartTotalPrice },
      ];
      return summary;
    },
    getDeliveryFees() {
      const { deliveryFees } = this.getCartStore;
      return this.isFreeDelivery ? deliveryFees : 0;
    },
    isMinimum() {
      const mno = this.getCartStore.minimumOrderPrice || 0;
      return this.getCartTotalPrice >= mno;
    },
    getMinOrderPrice() {
      const mno = this.getCartStore.minimumOrderPrice || 0;
      const region = this.getCartStore.region;
      return (
        region?.currency +
        " " +
        mno?.toLocaleString({ minimumFractionDigits: 2 })
      );
    },
    getDefaultAddressString() {
      const { activeAddress: a } = this.$getUserInfo();
      return `${a.title} / ${a.buildingNumber} ${a.street}, ${
        a.area ? a.area : ""
      } (
        ${a.latitude.toLocaleString({ minimumFractionDigits: 5 })} -
        ${a.longitude.toLocaleString({ minimumFractionDigits: 5 })})`;
    },
    getDefaultAddressStringNoTitle() {
      const { activeAddress: a } = this.$getUserInfo();
      return `${a.buildingNumber} ${a.street}, ${a.area ? a.area : ""} (
        ${a.latitude.toLocaleString({ minimumFractionDigits: 5 })} -
        ${a.longitude.toLocaleString({ minimumFractionDigits: 5 })})`;
    },
  },
  methods: {
    ...mapActions("cart", ["checkoutCart"]),
    ...mapActions("stores", ["loadStore", "isLocationInAos"]),
    ...mapActions("addresses", ["loadAddresses"]),

    goToCart() {
      this.$$router.push({ name: "cart", params: { storeId: this.getStoreIdParam, back: { name: this.$route.name } }})
    },
    tryCheckoutCart() {
      if(this.notEmptyArray(this.getCartItems) && !this.isCartLocked) {
        this.checkoutCart(this.getStoreIdParam)
          .then(() => {
            // TODO: show order progress by passing order data.id
            this.$$router.push({ name: "orders", params: { back: { name: this.$route.name }} });
          }).catch((response) => {
            // TODO: if there is a feedback show proper messages
            console.log(response);
          });
      }
    },
  },
};
</script>
